<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.date_create')"
                :placeholder="$t('labels.date_create')"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.tracking')"
                :placeholder="$t('labels.tracking')"
                name="tracking_id"
                sort-name="tracking_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.container_code')"
                :placeholder="$t('labels.container_code')"
                name="container_code"
                sort-name="container_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.request_quantity')"
                :placeholder="$t('labels.request_quantity')"
                name="box_quantity"
                sort-name="box_quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.receipted')"
                :placeholder="$t('labels.receipted')"
                name="receipt_box_quantity"
                sort-name="receipt_box_quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td>{{ item.tracking_id }}</td>
            <td>{{ item.container_code }}</td>
            <td>{{ formatNumber(item.box_quantity) }}</td>
            <td>{{ formatNumber(item.receipt_box_quantity) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="4">
        <div class="d-flex table-footer-count">
          <div class="mr-2 pt-3">
            {{ $t("labels.sum") }}: <b>{{ formatNumber(total) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex justify-end w-100">
          <v-text-field
            dense
            outlined
            hide-details
            v-model.number="page"
            :label="$t('labels.page')"
            :placeholder="$t('labels.page')"
            class="mr-2"
            style="max-width: 120px"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  debounce,
  downloadExcelFile,
  formatNumber,
  formatDateTime,
  getUrlParameter,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";

export default {
  name: "ReceiptHistory",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    total: 0,
    items: [],
    filters: {},
    isLoading: false,
    propertyDialog: false,
    costDialog: false,
    uidDialog: false,
    uidSku: null,
    updatingItem: {},
    sum: {},
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    downloadExcelFile,
    formatNumber,
    formatDateTime,
    getUrlParameter,

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }
      this.isLoading = true;
      const filename = "hang-hoa-dc.xlsx";

      try {
        await this.downloadExcelFile(
          "/dc/v1/goods-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/dc/v1/receipt-list", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.items = [...data.rows];
        });
    }, 500),
  },
};
</script>
